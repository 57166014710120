import classNames from "classnames"
import { useShop } from "hooks/firestore/simple/useShop"
import gastromiaLogo from "images/gastromia_logo.png"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import { Menu } from "styled-icons/boxicons-regular"
import { Phone } from "styled-icons/boxicons-solid"

export const navbarPaddingInRem = 2
export const navbarHeightInRem = 6 + navbarPaddingInRem
export const bannerHeightInRem = 3

export default function Navbar() {
  const shop = useShop()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  if (!shop) return null

  const shopImageUrl = typeof shop.image === "string" ? shop.image : shop.image?.url
  const navbarBackgroundColor = shop.landingpage?.navbar?.backgroundColor || "white"
  const navbarTextColor = shop.landingpage?.navbar?.textColor || "black"

  const NavLinks = () => (
    <ul className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center tw-gap-8 tw-m-0">
      <li>
        <Link href="/">
          <a
            className="tw-no-underline tw-text-lg visited:tw-text-black hover:tw-text-black"
            style={{ color: navbarTextColor }}
          >
            Home
          </a>
        </Link>
      </li>
      <li>
        <Link href="#kontakt">
          <a
            className="tw-no-underline tw-text-lg visited:tw-text-black hover:tw-text-black"
            style={{ color: navbarTextColor }}
          >
            Kontakt
          </a>
        </Link>
      </li>
      <li>
        <Link href="/bestellung">
          <a
            style={{
              background: "var(--gradient)",
              color: "var(--gradientText)",
            }}
            className={classNames(
              "tw-no-underline tw-text-lg visited:tw-text-black hover:tw-text-black tw-rounded-lg tw-px-6 tw-py-3"
            )}
          >
            Bestellen
          </a>
        </Link>
      </li>
    </ul>
  )

  return (
    <div>
      {shop.landingpage?.specialElements?.banner?.active && (
        <div
          style={{ height: `${bannerHeightInRem}rem`, padding: `0 ${navbarPaddingInRem}rem` }}
          className="tw-bg-black tw-w-full tw-z-50 tw-flex tw-flex-row tw-items-center tw-justify-center"
        >
          <p className="tw-text-white tw-text-lg tw-m-0">{shop.landingpage?.specialElements?.banner?.text}</p>
        </div>
      )}
      <div
        style={{ height: `${navbarHeightInRem}rem`, backgroundColor: navbarBackgroundColor }}
        className="tw-relative tw-w-full tw-z-50 tw-flex tw-flex-row tw-items-center tw-justify-center tw-overflow-hidden tw-py-4"
      >
        <div className="tw-max-w-5xl tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-full tw-px-5">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-10">
            <Image
              className="tw-object-contain"
              width={100}
              height={100}
              layout="fixed"
              src={shopImageUrl || gastromiaLogo}
              alt="Gastromia Logo"
            />
            {shop.contact.phone && (
              <Link passHref href={`tel:${shop.contact.phone}`}>
                <span style={{ color: navbarTextColor }} className="tw-no-underline tw-text-xl tw-cursor-pointer">
                  <Phone size={28} className="tw-mr-2" />
                  {shop.contact.phone}
                </span>
              </Link>
            )}
          </div>
          <div className="sm:tw-hidden tw-relative tw-flex tw-flex-col tw-items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="tw-text-2xl"
              style={{ color: navbarTextColor }}
            >
              <Menu size={32} />
            </button>
          </div>

          <nav className="tw-hidden sm:tw-flex tw-h-full tw-flex-row tw-items-center">
            <NavLinks />
          </nav>
        </div>
        {isMenuOpen && (
          <div className="sm:tw-hidden tw-absolute tw-top-full tw-left-0 tw-w-full tw-bg-white tw-shadow-lg tw-py-8">
            <NavLinks />
          </div>
        )}
      </div>
    </div>
  )
}
