import HeroOrderingForm from "components/landingpage/HeroOrderingForm"
import { bannerHeightInRem, navbarHeightInRem } from "components/landingpage/Navbar"
import { useShopStatus } from "hooks/firestore/advanced/useShopStatus"
import { useShop } from "hooks/firestore/simple/useShop"

export default function Hero() {
  const shop = useShop()
  const shopStatus = useShopStatus()

  return (
    <section
      className={`tw-bg-cover tw-bg-top `}
      style={{
        backgroundImage: shop?.landingpage?.hero?.image && `url(${shop.landingpage.hero.image.url})`,
        height: shop?.landingpage?.specialElements?.banner?.active
          ? `calc(75vh - ${navbarHeightInRem}rem - ${bannerHeightInRem}rem)`
          : `calc(75vh - ${navbarHeightInRem}rem)`,
      }}
    >
      <div className="tw-mx-auto tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
        {shop?.landingpage?.specialElements?.bannerInHero?.active &&
          shop?.landingpage.specialElements.bannerInHero.text && (
            <div
              style={{ borderColor: "var(--primary)" }}
              className="tw-border-4 tw-backdrop-filter tw-backdrop-blur-lg tw-bg-white tw-bg-opacity-10 tw-rounded-2xl tw-p-8 tw-shadow-lg tw-max-w-2xl tw-flex tw-flex-col tw-items-center tw-w-full"
            >
              <p
                className="tw-text-pretty tw-text-xl tw-text-center tw-text-white tw-mb-0 tw-prose tw-whitespace-pre-wrap"
                style={{ color: shop?.landingpage?.specialElements?.bannerInHero?.textColor || "#FFFFFF" }}
              >
                {shop.landingpage.specialElements.bannerInHero.text}
              </p>
            </div>
          )}
        <div className=" tw-bg-white tw-bg-opacity-15 tw-rounded-2xl tw-p-8 tw-shadow-2xl tw-max-w-2xl tw-flex tw-flex-col tw-items-center">
          <h1
            className="tw-max-w-5xl tw-font-bold tw-mb-4 tw-text-center tw-text-balance"
            style={{ color: shop?.landingpage?.hero?.titleColor || "#FFFFFF" }}
          >
            {shop?.landingpage?.hero?.title || "Herzlich Willkommen bei " + shop?.name}
          </h1>
          <p
            className="tw-text-xl tw-mb-6 tw-text-center tw-text-balance"
            style={{ color: shop?.landingpage?.hero?.descriptionColor || "#FFFFFF" }}
          >
            {shop?.landingpage?.hero?.description}
          </p>
          {!shopStatus.loading && !shopStatus.shopIsOpen && (
            <p className="tw-text-balance tw-w-full tw-text-xl tw-text-white tw-font-bold tw-bg-red-700 tw-p-4 tw-mb-6 tw-text-center tw-rounded-lg">
              {shop?.customClosedShopMessage
                ? shop.customClosedShopMessage
                : "Wir nehmen gerade keine Bestellungen entgegen. Nutze doch die Zeit und stöbere ein bisschen."}
            </p>
          )}
          <HeroOrderingForm />
        </div>
      </div>
    </section>
  )
}
