import { Loader as LoaderIcon } from "components/LEGACY/styled-components/Icons"
import AddressInput from "components/utils/AddressInput"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useActiveDistributionOptions } from "hooks/firestore/advanced/useActiveDistributionOptions"
import { useDelayedBasket } from "hooks/firestore/advanced/useDelayedBasket"
import { useShopStatus } from "hooks/firestore/advanced/useShopStatus"
import { useShop } from "hooks/firestore/simple/useShop"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import Basket, { BasketAddress } from "types/firestore/basket"
import { DeliveryOption, PickupOption } from "types/firestore/distributionOption"
import Shop from "types/firestore/shop"
import isLocationInGeoJSON from "util-functions/isLocationInGeoJSON"

export default function HeroOrderingForm() {
  const { basket, updateBasket, commit } = useDelayedBasket()
  const distOptions = useActiveDistributionOptions()
  const shopStatus = useShopStatus()
  const shop = useShop()
  const router = useRouter()

  const deliveryOption: WithRef<DeliveryOption> = distOptions?.find(
    option => option.type === "delivery"
  ) as WithRef<DeliveryOption>
  const pickupOption: WithRef<PickupOption> = distOptions?.find(
    option => option.type === "pickup"
  ) as WithRef<PickupOption>

  const [orderMode, setOrderMode] = useState<"pickup" | "delivery">(() => {
    if (basket?.orderMode && ["pickup", "delivery"].includes(basket.orderMode as "pickup" | "delivery")) {
      return basket.orderMode as "pickup" | "delivery"
    }
    return shopStatus.deliveryOffered ? "delivery" : "pickup"
  })
  const [address, setAddress] = useState<Partial<BasketAddress>>({})
  const [isCheckingBounds, setIsCheckingBounds] = useState(false)

  const addressRegex = /[a-zA-ZäöüÄÖÜ .]+ [0-9]+[a-zA-Z]?/
  const zipRegex = shop?.contact?.country === "AT" ? /^[0-9]{4}$/ : /^[0-9]{5}$/

  const streetValid = addressRegex.test(address.address || "")
  const cityValid = (address.city?.length || 0) > 2
  const zipCodeValid = zipRegex.test(address.zipCode || "")
  const coordinatesValid = !!address.latitude && !!address.longitude

  const addressValid = streetValid && cityValid && zipCodeValid && coordinatesValid

  const [isWithinBounds, setIsWithinBounds] = useState(false)

  const lat = typeof shop?.contact.latitude === "string" ? parseFloat(shop?.contact.latitude) : shop?.contact.latitude
  const lng =
    typeof shop?.contact.longitude === "string" ? parseFloat(shop?.contact.longitude) : shop?.contact.longitude

  useEffect(() => {
    const checkBounds = async () => {
      if (orderMode === "delivery" && addressValid && deliveryOption && shopStatus.deliveryOffered) {
        setIsCheckingBounds(true)
        const isInBounds = await isLocationInGeoJSON(
          { latitude: address.latitude, longitude: address.longitude } as BasketAddress,
          deliveryOption.deliveryArea
        )

        setIsWithinBounds(isInBounds)
        setIsCheckingBounds(false)
      }
    }

    checkBounds()
  }, [orderMode, addressValid, deliveryOption, shopStatus.deliveryOffered, address.latitude, address.longitude])

  useEffect(() => {
    if (
      basket?.orderMode &&
      ["pickup", "delivery"].includes(basket.orderMode as "pickup" | "delivery") &&
      (basket.orderMode as "pickup" | "delivery") !== orderMode
    ) {
      setOrderMode(basket.orderMode as "pickup" | "delivery")
    }
  }, [basket?.orderMode])

  const selectOrderMode = (mode: "pickup" | "delivery") => {
    setOrderMode(mode || "pickup")

    if (!basket || mode === basket.orderMode) return

    updateBasket({
      orderMode: mode,
      distributionOption: mode === "pickup" ? pickupOption?._ref.id : deliveryOption?._ref.id,
      orderedFor: undefined,
      fee: 0,
      lowOrderSurcharge: 0,
      orderTimeMeta: undefined,
    } as Partial<Basket>)
  }

  const goToStorefront = ({
    address,
    orderMode,
    addressValid,
    isWithinBounds,
    isCheckingBounds,
    shop,
  }: {
    address: Partial<BasketAddress>
    orderMode: "pickup" | "delivery"
    addressValid: boolean
    isWithinBounds: boolean
    isCheckingBounds: boolean
    shop: Shop
  }) => {
    // Commit basket updates
    if (basket) {
      updateBasket({
        address: {
          address: address.address || "",
          city: address.city || "",
          zipCode: address.zipCode || "",
          latitude: address.latitude,
          longitude: address.longitude,
          name: "",
        },
      })

      commit()
    }

    const encodedAddress = encodeURIComponent(address.address || "")
    const encodedCity = encodeURIComponent(address.city || "")
    const encodedZip = encodeURIComponent(address.zipCode || "")
    const encodedLatitude = encodeURIComponent(address.latitude || "")
    const encodedLongitude = encodeURIComponent(address.longitude || "")
    const encodedLocality = encodeURIComponent(address.locality || "")

    let baseUrl

    if (shop?.domain) {
      baseUrl = `/bestellung`
    } else {
      baseUrl = `/${shop?.shorthand}/bestellung`
    }

    router.push(
      orderMode === "delivery" && (addressValid || isWithinBounds || isCheckingBounds)
        ? `${baseUrl}?orderMode=${orderMode}${
            orderMode === "delivery"
              ? `&address=${encodedAddress}&city=${encodedCity}&zipCode=${encodedZip}&latitude=${encodedLatitude}&longitude=${encodedLongitude}&locality=${encodedLocality}`
              : ""
          }`
        : `${baseUrl}?orderMode=${orderMode}`
    )
  }

  return (
    <>
      <div className="tw-mb-4 tw-w-full">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-gap-y-4">
          <div className="tw-flex tw-gap-x-2 tw-items-center tw-w-full tw-justify-center">
            {shopStatus.deliveryOffered && (
              <button
                disabled={!shopStatus.deliveryOffered}
                className="tw-px-4 tw-py-2 tw-flex-1 tw-font-bold tw-rounded-lg tw-text-lg"
                style={{
                  background: orderMode === "delivery" ? "var(--gradient)" : "",
                  color: orderMode === "delivery" ? "var(--gradientText)" : "white",
                }}
                onClick={() => selectOrderMode("delivery")}
              >
                LIEFERUNG
              </button>
            )}
            {shopStatus.pickupOffered && (
              <button
                className="tw-px-4 tw-py-2 tw-flex-1 tw-font-bold tw-rounded-lg tw-text-lg"
                style={{
                  background: orderMode === "pickup" ? "var(--gradient)" : "",
                  color: orderMode === "pickup" ? "var(--gradientText)" : "white",
                }}
                onClick={() => selectOrderMode("pickup")}
              >
                ABHOLUNG
              </button>
            )}
          </div>
          {orderMode === "delivery" && (
            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              {!lat || !lng ? (
                <LoaderIcon size="19" />
              ) : (
                <AddressInput
                  address={address}
                  setAddress={setAddress}
                  coordinates={{ lat, lng }}
                  showMissingHouseNumberPopup
                />
              )}
            </div>
          )}
        </div>
      </div>

      <button
        onClick={() => {
          if (shop) {
            goToStorefront({
              address,
              orderMode,
              addressValid,
              isWithinBounds,
              isCheckingBounds,
              shop,
            })
          }
        }}
        disabled={orderMode === "delivery" && (!addressValid || !isWithinBounds || isCheckingBounds)}
        style={{
          background:
            orderMode === "delivery" && (!addressValid || !isWithinBounds || isCheckingBounds)
              ? "gray"
              : "var(--gradient)",
          color:
            orderMode === "delivery" && (!addressValid || !isWithinBounds || isCheckingBounds)
              ? "lightgray"
              : "var(--gradientText)",
          pointerEvents:
            orderMode === "delivery" && (!addressValid || !isWithinBounds || isCheckingBounds) ? "none" : "auto",
        }}
        className="tw-cursor-pointer tw-mb-2 tw-font-bold tw-text-lg tw-w-full tw-rounded-lg tw-px-6 tw-py-3 tw-text-center"
      >
        {shopStatus.shopIsOpen ? "JETZT BESTELLEN" : "JETZT STÖBERN"}
      </button>
      {orderMode === "delivery" ? (
        <p className="tw-text-white tw-m-0">Lieferung dauert aktuell {deliveryOption?.preparationTime} Minuten.</p>
      ) : (
        <p className="tw-text-white tw-m-0">Abholung dauert aktuell {pickupOption?.preparationTime} Minuten.</p>
      )}
      {addressValid && !isWithinBounds && !isCheckingBounds && (
        <p className="tw-text-white tw-text-lg tw-mt-3 tw-px-4 tw-py-1 tw-bg-red-600">
          Wir liefern leider nicht zu deiner Adresse.
        </p>
      )}
    </>
  )
}
