import { useShop } from "hooks/firestore/simple/useShop"

export default function ContactSection() {
  const shop = useShop()
  const shopEmail = shop?.contact.email
  const shopPhone = shop?.contact.phone
  const shopAddress = shop?.contact.address
  const shopCity = shop?.contact.city
  const shopZip = shop?.contact.zipCode
  const shopCountry = shop?.contact.country
  const socialMedia = shop?.socialMedia

  return (
    <section id="kontakt" className="tw-py-12 tw-bg-gray-100 tw-scroll-mt-12">
      <div className="tw-container tw-mx-auto tw-px-4 tw-flex tw-flex-col tw-items-center tw-justify-center">
        <h2 className="tw-text-2xl tw-font-bold tw-text-center">Kontakt</h2>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <p className="tw-text-center tw-text-gray-500">Kontaktieren Sie uns bei Fragen oder Problemen.</p>
        </div>

        <div className="tw-mt-8 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-max-w-2xl">
          {shopPhone && (
            <div className="tw-flex tw-items-center tw-space-x-3 tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-6 tw-w-6 tw-text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <a href={`tel:${shopPhone}`}>{shopPhone}</a>
            </div>
          )}
          {shopEmail && (
            <div className="tw-flex tw-items-center tw-space-x-3 tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-6 tw-w-6 tw-text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <a href={`mailto:${shopEmail}`}>{shopEmail}</a>
            </div>
          )}
        </div>
        {(shopAddress || shopCity || shopZip || shopCountry) && (
          <div className="tw-mt-8 tw-text-center">
            <h3 className="tw-text-xl tw-font-semibold tw-mb-2">Adresse</h3>
            <p className="tw-text-gray-600">
              {shopAddress && <span className="tw-block">{shopAddress}</span>}
              {shopZip && shopCity && <span className="tw-block">{`${shopZip} ${shopCity}`}</span>}
              {shopCountry && <span className="tw-block">{shopCountry}</span>}
            </p>
          </div>
        )}
        {socialMedia && (
          <div className="tw-mt-8 tw-text-center">
            <h3 className="tw-text-xl tw-font-semibold tw-mb-2">Social Media</h3>
            <div className="tw-flex tw-justify-center tw-space-x-4">
              {socialMedia.facebook && (
                <a href={socialMedia.facebook} target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
              )}
              {socialMedia.instagram && (
                <a href={socialMedia.instagram} target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>
              )}
              {socialMedia.x && (
                <a href={socialMedia.x} target="_blank" rel="noopener noreferrer">
                  X
                </a>
              )}
              {socialMedia.linkedin && (
                <a href={socialMedia.linkedin} target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              )}
              {socialMedia.youtube && (
                <a href={socialMedia.youtube} target="_blank" rel="noopener noreferrer">
                  YouTube
                </a>
              )}
              {socialMedia.tiktok && (
                <a href={socialMedia.tiktok} target="_blank" rel="noopener noreferrer">
                  TikTok
                </a>
              )}
              {socialMedia.pinterest && (
                <a href={socialMedia.pinterest} target="_blank" rel="noopener noreferrer">
                  Pinterest
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
