import ContactSection from "components/landingpage/ContactSection"
import Footer from "components/landingpage/Footer"
import Hero from "components/landingpage/Hero"
import Navbar from "components/landingpage/Navbar"
import OpeningHoursSection from "components/landingpage/OpeningHoursSection"
import Popup from "components/landingpage/Popup"
import SectionBelowHero from "components/landingpage/SectionBelowHero"
import Theme from "components/styles/Theme"
import { useShop } from "hooks/firestore/simple/useShop"
import styles from "./Landingpage.module.css"

export default function Landingpage() {
  const shop = useShop()
  return (
    <div className={styles.landingpage}>
      <Theme uiColor={shop?.uiColor} customShopTheme={shop?.customTheme} />
      <Popup />
      <Navbar />
      <Hero />
      <SectionBelowHero />
      <ContactSection />
      <OpeningHoursSection />
      <Footer />
    </div>
  )
}
